<template>
    <n-list value="customers" text="客戶" :sortBy="sortBy" :sortDesc="sortDesc" :fields="fields" :items="customers" />
</template>

<script>
import { mapState } from 'vuex'

export default {
    data() {
        return {
            sortBy: 'name',
            sortDesc: false,
            fields: [
                {
                    key: 'name',
                    label: '名稱',
                    sortable: true,
                },
                {
                    key: 'phone',
                    label: '電話號碼',
                    sortable: true,
                },
                {
                    key: 'pointBalance',
                    label: '積分結餘',
                    formatter: value => {
                        return `${value}分`
                    },
                    sortable: true,
                },
            ]
        }
    },

    created() {
        this.$store.dispatch('bindCollectionByMerchant', { col: 'customers', merchantId: localStorage.merchantId })
    },

    computed: {
        ...mapState({
            customers: state => state.customers,
        })
    }
}
</script>